import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "currentColor" }, [
      _createElementVNode("path", { d: "M16 31a15 15 0 1 1 15-15 15 15 0 0 1-15 15m0-28a13 13 0 1 0 13 13A13 13 0 0 0 16 3" }),
      _createElementVNode("path", { d: "m20.24 21.66-4.95-4.95A1 1 0 0 1 15 16V8h2v7.59l4.66 4.65Z" })
    ], -1)
  ])))
}
export default { render: render }